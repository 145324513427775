import Menu from '../asset/images/akal_images/menu.svg'
import Logo_black from '../asset/images/akal_images/akal_logo.svg'
import Search from '../asset/images/akal_images/search.svg'
import Account from '../asset/images/akal_images/account.svg'
import Cart from '../asset/images/akal_images/cart.svg'
import AddIcon from '../asset/images/akal_images/add.svg'
import EditIcon from '../asset/images/akal_images/edit.svg'
import SaveIcon from '../asset/images/akal_images/save.svg'
import EyeIcon from '../asset/images/akal_images/eye.svg'
import EyeCloseIcon from '../asset/images/akal_images/eye-close.svg'
import DeleteIcon from '../asset/images/akal_images/Delete.svg'
import BannerSliderLine from '../asset/images/akal_images/banner_slider_line.svg'
import OfferCenterLine from '../asset/images/akal_images/offer_center_line.svg'
import SeeCollectionArrow from '../asset/images/akal_images/see_collection_arrow.svg'
import SortByIcon from '../asset/images/akal_images/sort_by.svg'
import PlpRightIcon from '../asset/images/akal_images/plp_right_icon.svg'
import PlpDropdownIcon from '../asset/images/akal_images/plp_dropdown_icon.svg'
import CurrencyIcon from '../asset/images/akal_images/currency_icon.svg'
import PlpFitIcon from '../asset/images/akal_images/plp_fit_icon.svg'
import FooterAkalLogo from '../asset/images/akal_images/footer_akal_logo.svg'
import FooterCallIcon from '../asset/images/akal_images/call_icon.svg'
import FooterMessageIcon from '../asset/images/akal_images/message_icon.svg'
import FooterTimeIcon from '../asset/images/akal_images/time_icon.svg'
import FooterFacebookIcon from '../asset/images/akal_images/facebook_icon.svg'
import FooterWhatsappIcon from '../asset/images/akal_images/whatsapp_icon.svg'
import FooterTwitterIcon from '../asset/images/akal_images/twitter_icon.svg'
import FooterInstagramIcon from '../asset/images/akal_images/instagram_icon.svg'
import ExitPopupIcon from '../asset/images/akal_images/exit_popup_icon.svg'
import HeaderFacebookIcon from '../asset/images/akal_images/header_facebook_icon.svg'
import HeaderWhatsappIcon from '../asset/images/akal_images/header_whatsapp_icon.svg'
import HeaderTwitterIcon from '../asset/images/akal_images/header_twitter_icon.svg'
import HeaderInstagramIcon from '../asset/images/akal_images/header_instagram_icon.svg'
import HeaderDropdownIcon from '../asset/images/akal_images/header_popup_dropdown_icon.svg'
import CopyrightsIcon from '../asset/images/akal_images/copyrights_icon.svg'
import FabricIcon from '../asset/images/akal_images/fabric_icon.svg'
import CustomSuit from '../asset/images/akal_images/customize_Icon.svg'
import MeasureIcon from '../asset/images/akal_images/measure_icon.svg'
import FooterPopupStoreIcon from '../asset/images/akal_images/footer_popup_store_icon.svg'
import FooterPopupChatIcon from '../asset/images/akal_images/footer_popup_chat_icon.svg'
import FooterPopupWhatsappIcon from '../asset/images/akal_images/footer_popup_whatsapp_icon.svg'
import FooterPopupCallIcon from '../asset/images/akal_images/footer_popup_call_icon.svg'
import FooterPopupArrowIcon from '../asset/images/akal_images/footer_popup_right_arrow_icon.svg'
import MenuWhite from '../asset/images/akal_images/menu-white.svg'
import SearchWhite from '../asset/images/akal_images/search-white.svg'
import AccountWhite from '../asset/images/akal_images/account-white.svg'
import CartWhite from '../asset/images/akal_images/cart-white.svg'
import ZoomIn from '../asset/images/akal_images/zoom.svg'
import ResetIcon from '../asset/images/akal_images/reset.svg'
import FabricCanvasIcon from '../asset/images/akal_images/fabric_canvas.svg'
import StyleCanvasIcon from '../asset/images/akal_images/canvas_style.svg'
import AccentsCanvasIcon from '../asset/images/akal_images/canvas_accents.svg'
import MeasureCanvasIcon from '../asset/images/akal_images/canvas_measure.svg'
import SummaryCanvasIcon from '../asset/images/akal_images/canvas_summary.svg'
import FaricCanvasNormalICon from '../asset/images/akal_images/canvas_fabric.svg'
import FabricCanvasActiveIcon from '../asset/images/akal_images/canvas_fabric_active.svg'
import StyleCanvasActiveIcon from '../asset/images/akal_images/canvas_style_active.svg'
import AccentsCanvasActiveIcon from '../asset/images/akal_images/canvas_accents_active.svg'
import MeasureCanvasActiveIcon from '../asset/images/akal_images/canvas_measure_active.svg'
import SummaryCanvasActiveIcon from '../asset/images/akal_images/canvas_summary_active.svg'
import StandardSizeIcon from '../asset/images/akal_images/standard_size.svg'
import CustomProfileIcon from '../asset/images/akal_images/custom_profie.svg'
import PreviousOrderIcon from '../asset/images/akal_images/previous_order.svg'
import PdpPopupLeftArrowIcon from '../asset/images/akal_images/pdp_popup_left_arrow_icon.svg'
import PdpPopupRightArrowIcon from '../asset/images/akal_images/pdp_popup_right_arrow_icon.svg'
import MesaurementPopupImg from '../asset/images/akal_images/images/measurement_popup_img.svg'
import CanvasArrow from '../asset/images/akal_images/arrow_canvas.svg'
import MeasurementCollar from '../asset/images/akal_images/images/measurement_collar_img.png'
import MeasurementBack from '../asset/images/akal_images/images/measurement_back_img.png'
import MeasurementWaist from '../asset/images/akal_images/images/measurement_waist_img.png'
import MeasurementSleeve from '../asset/images/akal_images/images/measurement_sleeve_img.png'
import MeasurementChest from '../asset/images/akal_images/images/measurement_chest_img.png'
import AdminCanvasFabric from '../asset/images/akal_images/admin_canvas_fabric_icon.svg'
import AdminCanvasCollar from '../asset/images/akal_images/admin_canvas_collar_icon.svg'
import AdminCanvasSleeve from '../asset/images/akal_images/admin_canvas_sleeve_icon.svg'
import AdminCanvasPlacket from '../asset/images/akal_images/admin_canvas_placket_icon.svg'
import AdminCanvasCuff from '../asset/images/akal_images/admin_canvas_cuff_icon.svg'
import AdminCanvasThread from '../asset/images/akal_images/admin_canvas_thread_icon.svg'
import AdminCanvasBack from '../asset/images/akal_images/admin_canvas_back_icon.svg'
import AdminCanvasPocket from '../asset/images/akal_images/admin_canvas_pocket_icon.svg'
import EmailIcon from '../asset/images/akal_images/emaiIIcon.svg'
import StoreIcon from '../asset/images/akal_images/storeIcon.svg'
import PencilCorporate from '../asset/images/akal_images/pencil.svg'
import DataCollectionCorporate from '../asset/images/akal_images/data-collection.svg'
import Remake from '../asset/images/akal_images/remake.svg'
import Tailoring from '../asset/images/akal_images/tailoring.svg'
import AdminCanvasHeader from '../asset/images/akal_images/admin_canvas_header_arrow.svg'
import GetMoreArrow from '../asset/images/akal_images/get_more_right_arrow.svg'
import PaymentIcon1 from '../asset/images/akal_images/payment-icon1.svg'
import PaymentIcon2 from '../asset/images/akal_images/payment-icon2.svg'
import PaymentIcon3 from '../asset/images/akal_images/payment-icon3.svg'
import PaymentIcon4 from '../asset/images/akal_images/payment-icon4.svg'
import OrderNow from '../asset/images/akal_images/order-now-icon.svg'
import BlogImg1 from '../asset/images/akal_images/images/blog_img1.png'
import BlogImg2 from '../asset/images/akal_images/images/blog_img2.png'
import BlogImg3 from '../asset/images/akal_images/images/blog_img3.png'
import BlogImg4 from '../asset/images/akal_images/images/blog_img4.png'
import BlogImg5 from '../asset/images/akal_images/images/blog_img5.png'
import BlogImg6 from '../asset/images/akal_images/images/blog_img6.png'



export const PATH = {
    img: {
        Menu, Logo_black, Search, Account, Cart, AddIcon, EditIcon, SaveIcon, EyeIcon, EyeCloseIcon, DeleteIcon, BannerSliderLine,
        OfferCenterLine, SeeCollectionArrow, SortByIcon, PlpRightIcon,
        PlpDropdownIcon, GetMoreArrow,
        CurrencyIcon, PlpFitIcon, FooterAkalLogo, FooterCallIcon, FooterMessageIcon, FooterTimeIcon,
        FooterFacebookIcon, FooterWhatsappIcon, FooterTwitterIcon, FooterInstagramIcon, ExitPopupIcon,
        HeaderFacebookIcon, HeaderWhatsappIcon, HeaderTwitterIcon, HeaderInstagramIcon, HeaderDropdownIcon, CopyrightsIcon,
        FabricIcon, CustomSuit, MeasureIcon, FooterPopupStoreIcon, FooterPopupChatIcon, FooterPopupWhatsappIcon, FooterPopupCallIcon,
        FooterPopupArrowIcon, MenuWhite,
        FabricIcon, CustomSuit, MeasureIcon, SearchWhite, AccountWhite, CartWhite,
        ZoomIn, FabricCanvasIcon, ResetIcon, StyleCanvasIcon, AccentsCanvasIcon, MeasureCanvasIcon, SummaryCanvasIcon,
        StyleCanvasActiveIcon, AccentsCanvasActiveIcon, MeasureCanvasActiveIcon, SummaryCanvasActiveIcon,
        FaricCanvasNormalICon, FabricCanvasActiveIcon, StandardSizeIcon, CustomProfileIcon, PreviousOrderIcon, PdpPopupLeftArrowIcon,
        PdpPopupRightArrowIcon, MesaurementPopupImg, CanvasArrow, MeasurementCollar, MeasurementBack, MeasurementWaist, MeasurementSleeve,
        MeasurementChest, AdminCanvasFabric, AdminCanvasCollar, AdminCanvasSleeve, AdminCanvasPlacket, AdminCanvasCuff,
        AdminCanvasThread, AdminCanvasBack, AdminCanvasPocket, EmailIcon, StoreIcon, PencilCorporate, DataCollectionCorporate, Remake, Tailoring, AdminCanvasHeader,
        PaymentIcon1, PaymentIcon2, PaymentIcon3, PaymentIcon4, OrderNow, BlogImg1, BlogImg2, BlogImg3, BlogImg4, BlogImg5, BlogImg6,
    }

}

export const SERVERIMG = {
    image: {
        headerPopup: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/header_popup/header_popup_img.png",
        home: {
            section1: {
                leftImg: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/home/get_more_left_img.png",
                centerImg: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/home/get_more_center_img.png",
                rightImg: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/home/get_more_right_img.png"
            },
            section2: {
                blazerLeftImg: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/home/blazer_left_img.png",
                blazerRightImg: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/home/blazer_right_img.png",
                suitLeftImg: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/home/suit_left_img.png",
                suitRightImg: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/home/suit_right_img.png",
            },
            section4: {
                leftImg: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/home/get_more_left_img2.png",
                rightImg: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/home/get_more_right_img2.png",
            }
        },
        plp: {
            plpImg: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/plp/plp_last_section_img.png",
        },
        login: {
            bannerImg: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/login/login_img.png",
        },
        signup: {
            bannerImg: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/signup/sign_up_img.png",
        },
        footer: {
            ourStory: {
                bannerLeftImg: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/footer/our_story/storybg.svg",
                bannerRightImg: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/footer/our_story/storybg2.svg",
            },
            corporateServices: {
                bannerLeftImg: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/footer/corporate_services/corporate-bg-img.svg",
                bannerRightImg: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/footer/corporate_services/corporate-bg-img2.svg",
            },
            returnsExchange: {
                bannerLeftImg: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/footer/return_exchange/exchange2.png",
                bannerRightImg: "https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/website/footer/return_exchange/exchangebg.svg",
            }
        }
    }
}

export const CONTENTS = {
    header: {
        newArrivals: "New Arrivals",
        fabrics: "Fabrics",
        ourStory: "Our Story",
        Account: "Account",
        Cart: "Cart",
        blazers: "Blazers",
        suits: "suits",
        twoPieceSuit: "2 piece suits",
        threePieceSuit: "3 piece suits",
        shirts: "Shirts",
        pants: "pants",
        popupHead1: "Dictum sit risus velit gravida.",
        popupHead2: "Risus tincidunt purus posuero",
        phone: "We’re available by phone ",
        phoneNumber: "+123-456-789",
        email: "info@example.com",
        copyrights: "Copyrights AKAL. All Rights Reserved.",
        orderNow: 'order now & save big',
        bookAppoinment: 'book a free appoinment',
    },
    home: {
        carouselcontent: {
            sliderHead1: "The Suit That Gets You Noticed",
            sliderHead2: "Define Your Style",
            sliderHead3: "Fashion Fades, AKAL Lasts",
            sliderContent1: "Skip the off-the-rack disasters. Our made-to-measure service guarantees a fit so perfect, you’ll wonder why you ever settled for anything less.",
            sliderContent2: "Let’s get one thing straight: style isn’t handed out on a silver platter. But don’t worry, we’ll help you find it. From fabric to fit, we’ll make sure you walk out knowing you own the room.",
            sliderContent3: "Fast fashion? That’s cute. Our pieces are designed to last longer than your attention span. And yeah, they’re planet-friendly too. You’re welcome.",
        },
        section1: {
            Head1: "The Best in Bespoke Men’s Suits",
            Content1: "Tired of suits that make you look like your dad at his first job interview? AKAL’s bespoke tailoring guarantees a fit that flatters both your body and your ego.",
            Head2: "Click Through for Custom Suits Online",
            Content2: "You’ve got a vision, but nobody’s delivering. That’s where we come in. AKAL’s bespoke suits are here to fill the void in your wardrobe. Don’t see exactly what you want? No problem, we’ll make it from scratch.",
            getmore: "BROWSE OR CUSTOMIZE",
        },
        section2: {
            shop: "Shop ",
            pant: "Pant",
            suit: "Suit",
        },
        section3: {
            Head1: "Design Your Dream Suit",
            offer: "50% OFF",
            Content1: 'Your suit, your rules. From fabric to the smallest detail, every part of your next AKAL suit screams “you.”',
            Head2: "Bonus",
            Content2: 'design it yourself and get 50% off. Because nothing says "custom" like saving big.',
            getmore: "CUSTOMIZE NOW AND SAVE BIG",
        },
        section4: {
            Head1: "YOU DESERVE A SUIT THAT’S AS UNIQUE AS YOU ARE",
            Head2: "Style is an art, and like all art, it takes time. We’re here to help you master it.",
            Content2: "You already know off-the-rack suits aren’t cutting it. Lucky for you, AKAL exists. Our job? Making sure you’ve got a suit that fits like a second skin. Your job? Looking effortlessly sharp. Easy, right?",
            getmore: "SCHEDULE A CONSULTATION",
        },
        section5: {
            style: "STYLE/",
            shirt: "SHIRTS",
            pants: "PANTS",
            suits: "SUITS",
            blazers: "BLAZERS",
            vests: "vests",
        },
        section6: {
            content: "HAVE A LOOK FOR NEW STYLE",
        }
    },
    footer: {
        section1: {
            Head1: "Looking for Personal Style Advice?",
            content1: "Style is an art, and like all art, it takes time. We’re here to help you master it.",
            askStyleExpert: "ASK A STYLE EXPERT",
            Head2: "FREE Shipping and Returns",
            content2: 'We’re so confident you’ll love your suit, we’ll take care of the shipping. And if you don’t love it? We’ll fix it for free. Seriously.',
            Head3: "Our Completely Custom Commitment",
            content3: 'You’re the designer now. Customize your AKAL suit exactly how you want it, then get ready for the compliments to start rolling in.'
        },
        section2: {
            newsletter: "Sign Up for Our Newsletter",
            content: "Missing out on style tips, exclusive deals, and insider discounts? Not on our watch. Join the AKAL community and never miss a beat.",
            submit: "SIGN UP NOW",
        },
        section3: {
            phone: "+66 81-809-1081",
            email: "mikegulati@gmail.com",
            day: "Monday to Friday",
            shopBy: "Shop by",
            shirt: "Shirts",
            pants: "Pants",
            suit: "Suit",
            blazer: "Blazer",
            vest: "Vest",
            company: "Company",
            ourStory: "Our Story",
            cooperateServices: "Corporate Service",
            ReturnsExchange: "Returns & Exchanges",
            privacyPolicy: "Privacy Policy",
            termsCondition: "Terms & Condition",
            faqtext: 'FAQ',
            blogtext: 'Blog',
            copyrightsContent1: "Copyright",
            copyrightsContent2: "AKAL. All rights reserved.",
        },
        popup: {
            Head: "We’re here to help you",
            Content: "aliquet risus non quis senectus et. In diam quam.",
            storeHead: "Store Appointment",
            storeContent: "With on-site tailoring",
            emailHead: "Email",
            emailContent: "Email us here",
            whatsappHead: "Whatsapp",
            whatsappContent: "+66 81-809-1081",
            callHead: "Call",
            callContent: "+66 81-809-1081",
        },
    },
    plp: {
        customDress: "CUSTOM DRESS",
        webContent: "Lorem ipsum dolor sit amet consectetur. Odio orci aenean etiam dictum non neque dui gravida.",
        mobilewebContent: "Cursus sodales commodo nunc aliquet risus non quis senectus et. In diam quam nisl laoreet.",
        blazers: "blazers",
        suits: "suits",
        vests: "vests",
        shirts: "shirts",
        pants: "pants",
        sortBy: "SORT BY",
        mostPopular: "Most Popular",
        newest: "Newest",
        price: "Price",
        customBlazer: "Want to take your outfit up a notch (or three)? A custom-tailored blazer is the answer. Pick your fabric, lapel style, and buttons. We’ll make sure your blazer fits your body like it was born there.",
        customSuit: "From fabric selection to structure, your suit, your rules. We guarantee a perfect fit, and if it's not quite right the first time, we’ve got your back with FREE alterations.Satisfaction ? Guaranteed.",
        customShirt: "Forget the basics. Why stick to black or white when you can go custom? Stripes, checks, polka dots—match it with your suit or wear it solo. The power of customization is in your hands.",
        customPant: "Step up your style from the ground up with custom dress pants. Whether you want to match them to your blazer or mix and match, we’ll make sure you stand out. Ready for the spotlight?",
        customVest: "Upgrade your formalwear game with a custom dress vest. Two buttons or three? Pockets or sleek lines? Whatever you choose, it’s all about what suits you.",

        section1: {
            Head1: "Looks that last",
            Head2: "Fit to Perfection Guaranteed",
            Content: "Personalize your handcrafted look, from durable fabrics down to the finest details. Take charge of your style and feel confident with our Perfect Fit Guarantee. Thousands of partners worldwide allow us to give you the best local service for fittings and alterations."
        },
        section2: {
            content1: "Perfect fit guarantee",
            content2: "Customizable",
            content3: "Get measured",
            content4: "Design your suit to fit you perfectly",
            Head: "mattis est tristique posuere pulvinar amet feugiat urna.",
            content5: "Dapibus ante tincidunt in at eu.",
            content6: "Facilisi aliquet morbi facilisis in auctor ultricies quam.",
            content7: "Fermentum imperdiet elementum volutpat libero in ipsum.",
            content8: "Design your suit to fit you perfectly.",
        }
    },
    pdp: {
        colorName: "Color:",
        customize: "customize",
        fabricDetail: "fabric detail",
        section1: {
            specifications: "Specifications",
            color: "Color",
            productCode: "Product Code",
            material: "Material",
            cut: "Cut",
        },
        section2: {
            Head: "HOW AKAL WORKS",
            head1: "select the fabric",
            content1: "Lorem ipsum dolor sit amet consectetur. Tincidunt dignissim quis nibh cursus eget sed vel. Est malesuada quisque integer sociis eget.",
            head2: "design your custom suit",
            content2: "Ac arcu ornare felis luctus at risus suspendisse mi morbi. Elit ut mi ullamcorper facilisis lacinia pellentesque quis. quis nibh cursus eget",
            head3: "get the measurement",
            content3: "Ornare a eget consequat id montes condimentum. Cursus ut at ut orci vehicula. Rhoncus et magnis at dolor.",
        },
    },
    profile: {
        addAddress: "Add address",
        viewAddress: "View address",
        logout: "logout",
        information: "information",
        measurementProfiles: "measurement profiles",
        orderHistory: "order history",
        paymentMethod: "payment method",
        Head: "We are guaranteed",
        head1: "perfect fit guarantee",
        content1: "Ac arcu ornare felis luctus at risus suspendisse mi morbi. Elit ut mi ullamcorper facilisis lacinia pellentesque quis. quis nibh cursus eget",
        head2: "customizable",
        content2: "Ac arcu ornare felis luctus at risus suspendisse mi morbi. Elit ut mi ullamcorper facilisis lacinia pellentesque quis. quis nibh cursus eget",
        head3: "get measured",
        content3: "Ac arcu ornare felis luctus at risus suspendisse mi morbi. Elit ut mi ullamcorper facilisis lacinia pellentesque quis. quis nibh cursus eget",

        informationContent: {
            edit: "edit",
            changePassword: "change password",
            save: "save",
            close: "close",
            firstName: "first name",
            emailID: "email id",
            password: "password",
        },
        measurementProfilesContent: {
            Head: "Shirt_y973957",
            lastUpdated: "Last Updated",
            addMeasurement: "add measurement",
            popup: {
                head: "Size measurements",
                content: "Your measurement is now complete",
                collar: "collar",
                inches1: "45 in",
                back: "back",
                inches2: "15 in",
                waist: "waist",
                inches3: "15 in",
                sleeve: "sleeve",
                inches4: "15 in",
                chest: "chest",
                inches5: "15 in",
                close: "close",
                alterMeasurement: "Alter measurement",
            }
        },
        paymentMethodContent: {
            head: "Shirt_y973957",
            lastUpdated: "Last Updated",
        },
        orderHistoryContent: {
            noOrder: "You have no orders",
            lastUpdated: "Last Updated:",
            back: "back",
            order: "Order: ",
            orderDate: "Order Date: ",
            Processing: "Processing",
            readyDelivery: "Ready for Delivery",
            trackTrace: "Track and Trace",
            qty: "Qty:",
            name: "Name",
            email: "Email",
            phoneNumber: "Phone Number",
            address: "Address",
            total: "Total:",
        },
        addAddressPopup: {
            address: "address",
            country: "country",
            chooseCountry: "choose country",
            phone: "phone",
            address: "address",
            city: "city",
            state: "state",
            zipcode: "zipcode",
            close: "close",
        },
        viewAddressPopup: {
            chooseYourAddress: "Choose your address",
            listOfAddresses: "List of addresses",
            addAddress: "Add Address",
            phone: "Phone-",
            close: "close",
            proceed: "proceed",
        },
    },
    canvas: {
        zoom: "zoom",
        fabricDetails: "fabric details",
        reset: "reset",
        fabric: "Fabric",
        style: "Style",
        accents: "Accents",
        measure: "Measure",
        summary: "Summary",

        measureContent: {
            addYourMeasurement: "Add your measurement",
            selectStandardSize: "select a standard size",
            copyPreviousOrder: "copy from a previous order",
            measurePopup: {
                selectYourSize: "Select your size",
                pickTheSize: "Pick the size you usually wear.",
                short: "Short",
                regular: "Regular",
                long: "Long",
                chooseYourMeasurements: "Choose your measurements",
                content: "List of measurement",
            }
        },
        summaryContent: {
            yourSummary: "Your Summary",
            fabric: "fabric",
            style: "style",
            accents: "accents",
            measurement: "measurement",
        }
    },
    cart: {
        shoppingCart: "shopping cart",
        quantity: "Quantity:",
        subtotal: "subtotal:",
        priceDetails: "Price Details ( 1 item )",
        priceDetailsContent: "Mus id aenean massa montes.",
        totalProduct: "Total product",
        shippingCost: "Shipping Cost",
        free: "FREE",
        orderTotal: "order total",
        checkout: "checkout",
        continueShopping: "continue shopping",
    },
    signup: {
        signUp: "Sign up",
        content: "Become a member today",
        fullName: "full name",
        email: "email",
        password: "password",
        confirmPassword: "confirm password",
        acceptThe: "Accept the",
        termsConditions: "Terms & Conditions",
        alreadyAccount: "Already have an account?",
        login: "Login",
    },
    login: {
        login: "Login",
        content: "Enter your details to get log in to your account",
        invalidError: "Invalid Email or Password",
        fullName: "full name",
        password: "password",
        rememberMe: "remember me",
        forgotPassword: "forgot password",
        account: "Don’t have an account?",
        signup: "Sign Up",
    },
    privacyPolicy: {
        head: "Privacy Policy for akal",
        para1Part1: "At akal, accessible from",
        akalLink: "https://worldofakal.com",
        para1Part2: ", one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by akal and how we use it.",
        para2: "If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.",
        para3: "This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in akal. This policy is not applicable to any information collected offline or via channels other than this website. Our Privacy Policy was created with the help of the ",
        privacyLink: "Free Privacy Policy Generator",
        head1: "Consent",
        head1Para: "By using our website, you hereby consent to our Privacy Policy and agree to its terms.",
        head2: "Information we collect",
        head2Para1: "The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.",
        head2Para2: "If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.",
        head2Para3: "When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.",
        head3: "How we use your information",
        head3Para: "We use the information we collect in various ways, including to:",
        head3Point1: "Provide, operate, and maintain our website",
        head3Point2: "Improve, personalize, and expand our website",
        head3Point3: "Understand and analyze how you use our website",
        head3Point4: "Develop new products, services, features, and functionality",
        head3Point5: "Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes",
        head3Point6: "Send you emails",
        head3Point7: "Find and prevent fraud",
        head4: "Log Files",
        head4Para: "akal follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.",
        head5: "Cookies and Web Beacons",
        head5Para1: "Like any other website, akal uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.",
        head5Para2: "For more general information on cookies, please read",
        head5Para2Link: `"Cookies" article from the Privacy Policy Generator.`,
        head6: "Advertising Partners Privacy Policies",
        head6Para1: "You may consult this list to find the Privacy Policy for each of the advertising partners of akal.",
        head6Para2: "Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on akal, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.",
        head6Para3: "Note that akal has no access to or control over these cookies that are used by third-party advertisers.",
        head7: "Third Party Privacy Policies",
        head7Para1: "akal's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.",
        head7Para2: "You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.",
        head8: "CCPA Privacy Rights (Do Not Sell My Personal Information)",
        head8Para1: "Under the CCPA, among other rights, California consumers have the right to:",
        head8Para2: "Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.",
        head8Para3: "Request that a business delete any personal data about the consumer that a business has collected.",
        head8Para4: "Request that a business that sells a consumer's personal data, not sell the consumer's personal data.",
        head8Para5: "If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.",
        head9: "GDPR Data Protection Rights",
        head9Para1: "We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:",
        head9Para2: "The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.",
        head9Para3: "The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.",
        head9Para4: "The right to erasure – You have the right to request that we erase your personal data, under certain conditions.",
        head9Para5: "The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.",
        head9Para6: "The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.",
        head9Para7: "The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.",
        head9Para8: "If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.",
        head10: "Children's Information",
        head10Para1: "Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.",
        head10Para2: "akal does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.",
    },
    termsConditions: {
        head: "Terms and Conditions",
        para1: 'Welcome to akal!',
        para2: "These terms and conditions outline the rules and regulations for the use of akal's Website, located at",
        akalLink: "https://worldofakal.com",
        para3: "By accessing this website we assume you accept these terms and conditions. Do not continue to use akal if you do not agree to take all of the terms and conditions stated on this page.",
        para4: `The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.`,
        head1: "Cookies",
        head1Para1: "We employ the use of cookies. By accessing akal, you agreed to use cookies in agreement with the akal's Privacy Policy.",
        head1Para2: "Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.",
        head2: "License",
        head2Para1: "Unless otherwise stated, akal and/or its licensors own the intellectual property rights for all material on akal. All intellectual property rights are reserved. You may access this from akal for your own personal use subjected to restrictions set in these terms and conditions.",
        head2Para2: "You must not:",
        head2Point1: "Republish material from akal",
        head2Point2: "Sell, rent or sub-license material from akal",
        head2Point3: "Reproduce, duplicate or copy material from akal",
        head2Point4: "Redistribute content from akal",
        head2Para3: "This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the",
        termsConditionLink: "Terms And Conditions Generator",
        head2Para4: "Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. akal does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of akal,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, akal shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.",
        head2Para5: "akal reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.",
        head2Para6: "You warrant and represent that:",
        head2Para6Point1: "You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;",
        head2Para6Point2: "The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;",
        head2Para6Point3: "The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy",
        head2Para6Point4: "The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.",
        head2Para7: "You hereby grant akal a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.",
        head3: "Hyperlinking to our Content",
        head3Para1: "The following organizations may link to our Website without prior written approval:",
        head3Para1Point1: "Government agencies;",
        head3Para1Point2: "Search engines;",
        head3Para1Point3: "News organizations;",
        head3Para1Point4: "Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and",
        head3Para1Point5: "System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.",
        head3Para2: "These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.",
        head3Para3: "We may consider and approve other link requests from the following types of organizations:",
        head3Para3Point1: "commonly-known consumer and/or business information sources;",
        head3Para3Point2: "dot.com community sites;",
        head3Para3Point3: "associations or other groups representing charities;",
        head3Para3Point4: "online directory distributors;",
        head3Para3Point5: "internet portals;",
        head3Para3Point6: "accounting, law and consulting firms; and",
        head3Para3Point7: "educational institutions and trade associations.",
        head3Para4: "We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of akal; and (d) the link is in the context of general resource information.",
        head3Para5: "These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.",
        head3Para6: "If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to akal. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.",
        head3Para7: "Approved organizations may hyperlink to our Website as follows:",
        head3Para7Point1: "By use of our corporate name; or",
        head3Para7Point2: "By use of the uniform resource locator being linked to; or",
        head3Para7Point3: "By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.",
        head3Para8: "No use of akal's logo or other artwork will be allowed for linking absent a trademark license agreement.",
        head4: "iFrames",
        head4Para: "Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.",
        head5: "Content Liability",
        head5Para: "We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.",
        head6: "Your Privacy",
        head6Para: 'Please read Privacy Policy',
        head7: "Reservation of Rights",
        head7Para: "We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.",
        head8: "Removal of links from our website",
        head8Para1: "If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.",
        head8Para2: "We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.",
        head9: "Disclaimer",
        head9Para1: "To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:",
        head9Para1Point1: "limit or exclude our or your liability for death or personal injury;",
        head9Para1Point2: "limit or exclude our or your liability for fraud or fraudulent misrepresentation;",
        head9Para1Point3: "limit any of our or your liabilities in any way that is not permitted under applicable law; or",
        head9Para1Point4: "exclude any of our or your liabilities that may not be excluded under applicable law.",
        head9Para2: "The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.",
        head9Para3: "As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.",
    },
    ourStory: {
        bannertext: "Because You Deserve a Suit That Screams 'You'",
        bannerHead: "The Story of AKAL",
        bannerContent: "Style is more than just clothes—it’s how you carry yourself, and let’s be real, it’s how people remember you. AKAL is here to make sure no one forgets. Our mission? Helping you nail your personal style so well that it becomes part of your DNA.",
        content1: "Whether you’re a suit-wearer by necessity or by choice, our bespoke tailoring is designed for anyone who wants to feel good, look better, and make an impression that lasts. And no, it’s not just for men—we’re here for anyone who wants to own their look.",
        head1: 'Sustainability Without the Sermon',
        content2: "Fast fashion? Nope, we don’t do that. At AKAL, we believe in making clothes that last. And yeah, we’re pretty proud of the fact that our fabrics are eco-friendly too. So not only do you look good, but you can feel good about it too.",
        head2: 'Education First – Style, a Close Second',
        content3Part1: "We get it—menswear hasn’t exactly been known for style tips and guides. But at AKAL, we’re on a mission to change that. Whether it’s learning what makes the perfect fit or diving deep into fabric choices, we’re here to make sure you know exactly what you need to own your style.",
        content3Part2: "Join our community of like-minded individuals, and let’s take this style adventure together.",
        joinnow: 'Join us now',
    },
    corporateServices: {
        bannerHead: "Corporate Services",
        bannerContent: "AKAL frequently works with corporations looking to create a uniform, but comfortable, look for their employees.",
        head1: "fully customizable",
        head1Para1: "We start at the drawing board. Let us know what you're looking for, what styles, color tones, fabric types, and a range of other information. This process allows for complete customization, no matter your logotype, fit preference, pattern style - we've got you covered.",
        head1Para2: "Based on the information you provide to us - our tailors' will mock up some samples that can be verified and adjusted by your team.",
        head1Para3: "Bulk orders allow us to increase our purchasing power when buying fabrics - we then pass those savings on to you in the form of a volume discount.",
        head2: "create a collection",
        head2Para1: "Create a company collection composed of our already available products. Share with us some information on the way your company operates, and we'd be happy to help you curate a collection of garments with the right color tones and level of formality to match your business's needs.",
        head2Para2: "This way your employees can individually select the look they prefer. Better yet, with enough volume, enjoy a substantial discount on all items purchased.",
        email: "Email:",
        emailLink: "corporate@mikegulati@gmail.com",
    },
    returnsExchange: {
        bannerHead: "Our Fit Guarantee",
        bannerContent: "All garments produced under the Paul’s Fashion umbrella adhere to a strict quality protocol. We strive to ensure a perfect fit every time.",
        head1: "Alterations",
        head1Para1: "If the product is near-perfect but requires a few adjustments to become a home-run, we recommend taking it to your local tailor for an alteration.",
        head1Para2: "Ask your tailor to provide you with an invoice/receipt for the cost of the alteration.",
        head1Para3: "Please send us a clear picture of the invoice via email, our socials, or WhatsApp. Make sure to include the order number in your message.",
        head1Para4: "We will reimburse you up to 25% of the order value.",
        head2: "Remakes",
        head2Para1: "In the rare event that your garment cannot be altered, we will remake it for you. Now, we're talking pigs flying rare - it's unlikely but possible.",
        head2Para2: "Tell us what went wrong and how we can fix it, and we'll get cracking on constructing your new garment from scratch.",
    },
    faq: {
        head: 'Frequently Asked Questions',
        para: "We're here to answer all your questions",
        heading1: 'Common Queries',
        heading2: 'Customized',
        heading3: 'Payments',
        question1: 'What is the delivery date?',
        answer1: 'We aim to deliver within 1-3 working days, depending on your destination. Check our shipping page for more information.',
        question2: 'How can I exchange or return an online order?',
        answer2: "Your online purchase(s) can be returned via Returnista. You'll find a link to the Returnista website in your delivery confirmation email.You can also find the instructions on our Return & Exchange page. If you want to exchange one or more articles, please visit one of our stores. We are happy to exchange or refund all item(s) as long as the item(s) are returned in a resellable condition(unworn, with original packaging, tags and/ or labels) within 30 days from the date of purchase. Please note, socks can not be returned.",
        question3: 'How can I exchange or return an item purchased in store?',
        answer3: 'Your purchase(s) can be returned and exchanged, at any AKAL store. Items will be refunded when returned in original condition, including price tags. Please make sure to bring your receipt. We are happy to exchange or refund all item(s) as long as the item(s) are returned in a resellable condition (with original packaging, tags and/or labels) within 30 days from the date of purchase. Please note that socks can not be returned.',
        question4: 'When will I receive my refund?',
        answer4: 'After we have received your returned items, your refund will be processed within 14 days.',
        question5: 'How can I track my order?',
        answer5: 'As soon as we start packing your order, we will send you a shipment confirmation email with the delivery information and a link to track your order. Please note, it can take a few days before the tracking information is uploaded.',
        question6: 'Where can I find the size guide of a product?',
        answer6: 'The size guides of our products can be found at the right upper corner on the product pages near the available sizes. ',
        question7: 'How do I measure my size?',
        answer7: 'Measure your favorite shirt, pants or suit and compare these measurements with our size guides. Always measure your clothes flat, this means on one side. Our size guides are also based on flat measurements and not on circumference measurements.',
        question8: 'How do I know my pants size?',
        answer8: 'Click on the size guide link on our product pages and check what size you need. As a rule of thumb you can add 16 to your regular waist size, so 32 will be a size 48. However do take into account short or long length. If you would like personal advice.',
        question9: 'Which payments does akal accept?',
        answer9: 'We accept payments by iDEAL, Credit Card, Mister Cash, and PayPal.',
        question10: 'Why am I unable to complete my payment?',
        answer10: 'Please contact our customer service at +66 81-809-1081  if you are experiencing any issues while placing an order.',
    },
    blog: {
        head: 'The Blog',
        para: 'Keep up to date with the latest & greatest in products, style tips, inside looks & all things AKAL.',
        imgText1: 'How to Style a Custom Suit',
        imgText2: 'Seasonal Suit Trends',
        imgText3: 'Client Spotlights',
        imgText4: 'Ethical Tailoring Practices',
        imgText5: 'Suit Inspiration Boards',
        imgText6: 'Formal Attire',
        heading1: 'Customization Options Explained',
        heading1Para1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        heading1Para2: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
        heading2: 'Virtual Styling Appointments',
        heading2Para1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        heading2Para2: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.',
    }
}