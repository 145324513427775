import React from 'react'
import './home.css'
import { withRouter } from 'react-router-dom'
import { CONTENTS, PATH, SERVERIMG } from '../../globals/app-constraints'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { HomeCarousel } from './home_carousel'
import ButtonDesign from '../../component/button'
import LazyLoad from 'react-lazyload';

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  navCustomClothingPage(type) {
    switch (type) {
      case 'shirt': {
        this.props.history.push('/shirt')
        break;
      } case 'pant': {
        this.props.history.push('/pant')
        break;
      } case 'suit': {
        this.props.history.push('/suit')
        break
      } case 'blazer': {
        this.props.history.push('/blazer')
        break
      } case 'vest': {
        this.props.history.push('/vest')
        break
      }
      default:
        return null
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="p-0" >
          <div className="main_banner w-100">
            <div className='overlay-bg'></div>
            <LazyLoad className="w-100 h-100 object-fit-cover">
              <video alt="banner-video" className="w-100 h-100 object-fit-cover" autoPlay={true} loop={true} muted playsInline>
                <source src='https://thepaulsfashion.s3.ap-southeast-1.amazonaws.com/prelaunch/banner_mobile.mp4' type="video/mp4" />
              </video>
            </LazyLoad>
            <HomeCarousel />
            <div className='button-design px-5'>
              <div className='w-500 d-flex flex-lg-row flex-column gap-3'>
                <ButtonDesign
                  btnWidth={'p-0 d-lg-flex w-100 button-primary banner-btn'}
                  className={'btn-getmore fs-16 H-cb py-12 w-100'}
                  ButtonValue={CONTENTS.header.bookAppoinment}
                  NoImg
                  onClick={() => this.navCustomClothingPage('blazer')}
                  arrowImg={PATH.img.arrowImg}
                />
                <ButtonDesign
                  btnWidth={'p-0 d-lg-flex w-100 button-primary'}
                  className={'btn-getmore fs-16 H-cb py-12 w-100'}
                  ButtonValue={CONTENTS.header.orderNow}
                  NoImg
                  onClick={() => this.props.history.push('/blazer')}
                  arrowImg={PATH.img.arrowImg}
                />
              </div>
            </div>
          </div>
          <div className='container mx-auto get_more_section d-flex gap-64'>
            <div className='d-flex gap-64 mobile_get_more_section_reverse'>
              <div className='get_more_left_section d-flex align-items-center justify-content-center'>
                <LazyLoad className="w-100">
                  <img src={SERVERIMG.image.home.section1.leftImg} className='w-100 get_more_left_section_img' alt="get-more-left-img" />
                </LazyLoad>
              </div>
              <div className='d-flex gap-44 get_more_center_section'>
                <div className='d-flex flex-column gap-16'>
                  <p className='H-cb fs-24 text-uppercase'>{CONTENTS.home.section1.Head1}</p>
                  <p className='H-l fs-14 letter-spacing'>{CONTENTS.home.section1.Content1}</p>
                </div>
                <div className='d-flex gap-44 get_more_center_section_img_div align-items-start justify-content-start'>
                  <LazyLoad className="w-100 h-100 object-fit-cover">
                    <img src={SERVERIMG.image.home.section1.centerImg} className='w-100 get_more_center_section_img' alt="get-more-center-img" />
                  </LazyLoad>
                </div>
              </div>
            </div>
            <div className='d-flex gap-44 get_more_right_section'>
              <div className='d-flex align-items-end justify-content-end get_more_right_section_img_div'>
                <LazyLoad className="w-100 h-100 object-fit-cover">
                  <img src={SERVERIMG.image.home.section1.rightImg} className='get_more_right_section_img' alt="get_more_right_img" />
                </LazyLoad>
              </div>
              <div className='d-flex flex-column gap-16 get_more_right_section_data'>
                <p className='H-cb fs-24 text-uppercase'>{CONTENTS.home.section1.Head2}</p>
                <p className='H-l fs-14 letter-spacing'>{CONTENTS.home.section1.Content2}</p>
                <ButtonDesign
                  btnWidth={'gap-12 p-0 d-flex'}
                  className={'fs-16 H-cb py-12'}
                  ButtonValue={CONTENTS?.home?.section1?.getmore}
                  NoImg
                  onClick={() => this.navCustomClothingPage('blazer')}
                  arrowImg={PATH?.img?.GetMoreArrow}
                />
              </div>
            </div>
          </div>
          <div className='blazer_section row mx-0'>
            <div className='col-lg-6 p-0 d-flex align-items-center justify-content-center home_img_section cursor-pointer' onClick={() => this.navCustomClothingPage('pant')}>
              <LazyLoad className="w-100 h-100 object-fit-cover">
                <img src={SERVERIMG.image.home.section2.blazerLeftImg} className='suit_blazer_img_section w-100' alt="blazer-left-img" />
              </LazyLoad>
            </div>
            <div className='col-lg-6 p-0 cursor-pointer position-relative d-flex align-items-center justify-content-center home_img_section_bg_color_change cursor-pointer' onClick={() => this.navCustomClothingPage('pant')}>
              <p className='H-cb fs-16 home_section_names letter-spacing p-4 text-lowercase'>{CONTENTS.home.section2.shop}<span className='text-decoration-underline text-lowercase'>{CONTENTS.home.section2.pant}</span></p>
              <LazyLoad className="w-100 h-100 object-fit-cover">
                <img src={SERVERIMG.image.home.section2.blazerRightImg} className='suit_blazer_img_section w-100' alt="blazer-right-img" />
              </LazyLoad>
            </div>
          </div>
          <div className='suit_section row mx-0 d-flex'>
            <div className='col-lg-6 p-0 cursor-pointer position-relative d-flex align-items-center justify-content-center home_img_section_bg_color_change' onClick={() => this.navCustomClothingPage('suit')}>
              <p className='H-cb fs-16 home_section_names letter-spacing p-4 text-lowercase'>{CONTENTS.home.section2.shop}<span className='text-decoration-underline text-lowercase'>{CONTENTS.home.section2.suit}</span></p>
              <LazyLoad className="w-100 h-100 object-fit-cover">
                <img src={SERVERIMG.image.home.section2.suitLeftImg} className='suit_blazer_img_section w-100' alt="suit-left-img" />
              </LazyLoad>
            </div>
            <div className='col-lg-6 p-0 d-flex align-items-center justify-content-center home_img_section cursor-pointer' onClick={() => this.navCustomClothingPage('suit')}>
              <img src={SERVERIMG.image.home.section2.suitRightImg} className='suit_blazer_img_section w-100' alt="suit-right-img" />
            </div>
          </div>
          <div className='row container mx-auto offer_section align-items-center'>
            <div className='col-lg-6 p-0'>
              <div className='d-flex flex-column gap-16 offer_section_left align-self-center'>
                <p className='H-l fs-14 letter-spacing'>{CONTENTS.home.section3.Head1}</p>
                <p className='H-l fs-80 letter-spacing mobile_offer_80'>{CONTENTS.home.section3.offer}</p>
                <p className='H-l fs-14 letter-spacing'>{CONTENTS.home.section3.Content1}</p>
              </div>
            </div>
            <div className='col-lg-6 p-0'>
              <div className='d-flex flex-column gap-24 offer_section_right align-self-center'>
                <p className='H-cb fs-24 text-uppercase'>{CONTENTS.home.section3.Head2}</p>
                <p className='H-l fs-14 letter-spacing'>{CONTENTS.home.section3.Content2}</p>
                <div>
                  <ButtonDesign
                    btnWidth={'p-0 d-flex button-primary'}
                    className={'btn-getmore fs-16 H-cb py-12'}
                    ButtonValue={CONTENTS.home.section3.getmore}
                    NoImg
                    onClick={() => this.props.history.push('/blazer')}
                    arrowImg={PATH.img.arrowImg}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='container mx-auto'>
            <p className='H-cb fs-44 get_more_left2_heading text-uppercase'>{CONTENTS.home.section4.Head1}</p>
            <div className='get_more_section2 row m-0'>
              <div className='col-lg-6 d-flex flex-column gap-24 get_more_left2 p-0'>
                <LazyLoad className="w-100 h-100 object-fit-cover">
                  <img src={SERVERIMG.image.home.section4.leftImg} alt="" className='w-100 get_more_left_img2' />
                </LazyLoad>
              </div>
              <div className='col-lg-6 get_more_right2 d-flex pt-lg-0 pt-4 ps-lg-4 ps-0 pe-0'>
                <div className='d-flex flex-column gap-16 get_more_right2_content'>
                  {/* <p className='PP_R fs-24'>{CONTENTS.home.section4.Head2}</p> */}
                  <p className='H-l fs-14 letter-spacing'>{CONTENTS.home.section4.Content2}</p>
                  <ButtonDesign
                    btnWidth={'p-0 d-flex'}
                    className={'btn-getmore button-primary fs-16 H-cb py-12'}
                    ButtonValue={CONTENTS.home.section4.getmore}
                    NoImg
                    onClick={() => this.props.history.push('/blazer')}
                    arrowImg={PATH.img.arrowImg}
                  />
                </div>
                <div className='d-flex align-items-end get_more_right_section2'>
                  <LazyLoad className="w-100 h-100 object-fit-cover d-flex align-items-end">
                    <img src={SERVERIMG.image.home.section4.rightImg} className='get_more_right_section2_img' alt="" />
                  </LazyLoad>
                </div>
              </div>
            </div>
          </div>
          <div className='container mx-auto style_section row'>
            <div className='col-lg-4 p-0'>
              <p className='H-cb fs-84 mobile_style_heading'>{CONTENTS.home.section5.style}</p>
            </div>
            <div className='col-lg-8 p-0'>
              <div className='d-flex justify-content-between style_section_border cursor-pointer' onClick={() => this.navCustomClothingPage('shirt')}>
                <p className='H-l fs-34 mobile_style_names letter-spacing text-uppercase'>{CONTENTS.home.section5.shirt}</p>
                <div className='d-flex gap-44 justify-content-center style_see_collection_section'>
                  <img src={PATH?.img?.SeeCollectionArrow} className='' alt="get_more_right_arrow" />
                </div>
              </div>
              <div className='d-flex justify-content-between style_section_border cursor-pointer' onClick={() => this.navCustomClothingPage('pant')}>
                <p className='H-l fs-34 mobile_style_names letter-spacing text-uppercase'>{CONTENTS.home.section5.pants}</p>
                <div className='d-flex gap-44 justify-content-center style_see_collection_section' >
                  <img src={PATH?.img?.SeeCollectionArrow} className='' alt="get_more_right_arrow" />
                </div>
              </div>
              <div className='d-flex justify-content-between style_section_border cursor-pointer' onClick={() => this.navCustomClothingPage('suit')}>
                <p className='H-l fs-34 mobile_style_names letter-spacing text-uppercase'>{CONTENTS.home.section5.suits}</p>
                <div className='d-flex gap-44 justify-content-center style_see_collection_section' >
                  <img src={PATH?.img?.SeeCollectionArrow} className='' alt="get_more_right_arrow" />
                </div>
              </div>
              <div className='d-flex justify-content-between style_section_border cursor-pointer' onClick={() => this.navCustomClothingPage('blazer')}>
                <p className='H-l fs-34 mobile_style_names letter-spacing text-uppercase'>{CONTENTS.home.section5.blazers}</p>
                <div className='d-flex gap-44 justify-content-center style_see_collection_section'>
                  <img src={PATH?.img?.SeeCollectionArrow} className='' alt="get_more_right_arrow" />
                </div>
              </div>
              <div className='d-flex justify-content-between style_section_border cursor-pointer' onClick={() => this.navCustomClothingPage('vest')}>
                <p className='H-l fs-34 mobile_style_names letter-spacing text-uppercase'>{CONTENTS.home.section5.vests}</p>
                <div className='d-flex gap-44 justify-content-center style_see_collection_section'>
                  <img src={PATH?.img?.SeeCollectionArrow} className='' alt="get_more_right_arrow" />
                </div>
              </div>
            </div>
          </div>
          <div className=''>
            <div className='style_banner d-flex flex-column justify-content-center align-items-center'>
              <p className='PP_R fs-16 letter-spacing style_banner_content text-uppercase'>{CONTENTS.home.section6.content}</p>
              <div className='w-500 d-flex flex-lg-row flex-column gap-3 p-0 pt-4 px-lg-0 px-5'>
                <ButtonDesign
                  btnWidth={'p-0 d-lg-flex w-100 button-primary banner-btn'}
                  className={'btn-getmore fs-16 H-cb py-12 w-100'}
                  ButtonValue={CONTENTS.header.bookAppoinment}
                  NoImg
                  onClick={() => this.navCustomClothingPage('blazer')}
                  arrowImg={PATH.img.arrowImg}
                />
                <ButtonDesign
                  btnWidth={'p-0 d-lg-flex w-100 button-primary'}
                  className={'btn-getmore fs-16 H-cb py-12 w-100'}
                  ButtonValue={CONTENTS.header.orderNow}
                  NoImg
                  onClick={() => this.props.history.push('/blazer')}
                  arrowImg={PATH.img.arrowImg}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <Body /> */}
      </React.Fragment>
    )
  }
}
export default withRouter(Home)